
import React from "react";
// nodejs library that concatenates classes
//import classnames from "classnames";

// reactstrap components
import { Badge, Button, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
//import CardsFooter from "components/Footers/CardsFooter.jsx";

// index page sections
//import Download from "../IndexSections/Download.jsx";
import SimpleFooter from "components/Footers/SimpleFooter.jsx";

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-100">
              <div className="shape shape-style-3 shape-dark">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col lg="6">
                      <h1 className="display-3 text-white">
                        คณิตคิดเร็ว หรือ 180IQ <span>เกมคณิตเร็วบนมือถือ</span>
                      </h1>
                      <p className="lead text-white">
                        เป็นเกมที่นำตัวเลขที่ได้จากการสุ่มโจทย์จำนวน 5 หลัก หรือ
                        4 หลัก มาดำเนินการทางคณิตศาตร์ เช่น การบวก การลบ การคูณ
                        การหาร ยกกำลัง ถอดราก แฟกทอเรียล ผลมรวม ผลคูณรวม
                        เพื่อให้ได้ตรงตามคำตอบที่ได้จากการสุ่ม
                        เป็นการฝึกการคิดเลขเร็วที่ใช้ทักษะหลายๆด้าน
                        และยังมีการแข่งขันศิลปหัตถกรรมในทุกปีอีกด้วย
                        ลองทดสอบความเร็วกันดูนะครับ
                      </p>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="secondary"
                          href="#"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i class="fa fa-apple" aria-hidden="true"></i>
                          </span>
                          <span className="btn-inner--text">coming soon</span>
                        </Button>
                        <Button
                          className="btn-icon mb-3 mb-sm-0 ml-1"
                          color="success"
                          href="https://play.google.com/store/apps/details?id=com.codestaeb.math180iq"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i class="fa fa-android" aria-hidden="true"></i>
                          </span>
                          <span className="btn-inner--text">Download</span>
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <img
                        width="500"
                        alt="..."
                        className="img-fluid floating"
                        src={require("assets/img/logo180.png")}
                      />
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>

          <section className="section section-lg pt-lg-0 mt--50">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Awesome features</h3>
                    <p>
                      การสุ่มตัวเลขที่ไม่ซ้ำกันตาม
                      เกณฑ์การแข่งขันศิลปหัตถกรรมนักเรียน
                      มีการจับเวลาที่สามารถตั้งเวลาได้เอง สามารถเลือกโจทย์แบบ 4
                      หลัก และ 5 หลัก และคำตอบแบบ 2 หลัก และ 3 หลัก
                      มีการออกแบบที่เป็นมิตรกับผู้ใช้.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i class="fa fa-random" aria-hidden="true"></i>
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              การสุ่มตัวเลขเป็นไปตามเกณฑ์การแข่งขัน คณิตคิดเร็ว
                              สพฐ.
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i class="fa fa-clock-o" aria-hidden="true"></i>
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">จับเวลาในการทำโจทย์ตามเกณฑ์</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i class="fa fa-cog" aria-hidden="true"></i>
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              สามารถตอบคำถามตามในแอพได้เลย
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
